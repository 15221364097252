export const getPageData = (pathname) => {
    switch (pathname) {
      case "/":
        return {
          backgroundImage: "/images/melbourne-city.webp",
          heading: "Groundwater now and for the future",
          description: "",
        };
      case "/about-the-logo/":
        return {
          backgroundImage: "/images/color-book.webp",
          heading: "About the Logo",
          description: "Discover the meaning and inspiration behind the logo of the 2025 World Groundwater Congress.",
        };
      case "/host-and-committee/":
        return {
          backgroundImage: "/images/waterdrop.webp",
          heading: "Host and Committee",
          description: "Meet the dedicated hosts and committee members orchestrating the 2025 World Groundwater Congress.",
        };
      case "/accommodation/":
        return {
          backgroundImage: "/images/waterdrop.webp",
          heading: "Accommodation",
          description: "Find the best places to stay during your event.",
        };
      case "/visit-victoria/":
        return {
          backgroundImage: "/images/mcec.webp",
          heading: "Visit Victoria",
          description: "Explore various destinations in Victoria.",
        };
      case "/congress-venue/":
        return {
          backgroundImage: "/images/mcec.webp",
          heading: "Congress Venue",
          description: "Get all the details about the congress venue, including location and facilities.",
        };
      case "/melbourne-bucket-list/":
        return {
          backgroundImage: "/images/mcec.webp",
          heading: "Melbourne Bucket List",
          description: "Discover must-see attractions and activities to enjoy while in Melbourne.",
        };
        case "/groundwater-bucket-list/":
        return {
          backgroundImage: "/images/mcec.webp",
          heading: "Groundwater Bucket List",
          description: "Here are the ideas to kickstart your Australian groundwater bucket list during your IAH 2025 stay!",
        };
      case "/sponsorship/":
        return {
          backgroundImage: "/images/waterdrop.webp",
          heading: "Sponsorship",
          description: "Explore our sponsorship opportunities and join us in making this event a success.",
        };
      case "/sponsors-and-exhibitors/":
        return {
          backgroundImage: "/images/waterdrop.webp",
          heading: "Sponsors & Exhibitors",
          description: "Discover our top-tier sponsors and exhibitors.",
        };
      case "/latest-news/":
        return {
          backgroundImage: "/images/waterdrop.webp",
          heading: "Latest News",
          description: "Stay updated with the latest news and updates.",
        };
      case "/promotional-toolkit/":
        return {
          backgroundImage: "/images/waterdrop.webp",
          heading: "Promotional Toolkit",
          description: "Download assets for your promotional materials.",
        };
      case "/contact/":
        return {
          backgroundImage: "/images/waterdrop.webp",
          heading: "Contact Us",
          description: "For all general enquiries, please contact the Congress Secretariat.",
        };
      case "/privacy-policy/":
        return {
          backgroundImage: "/images/waterdrop.webp",
          heading: "Privacy Policy",
          description: "Learn about our privacy policy and how we handle your personal data.",
        };
      case "/registration/":
        return {
          backgroundImage: "/images/waterdrop.webp",
          heading: "Registration",
          description: "Join leading experts at the upcoming event! Secure your spot now for insightful sessions, networking, and industry advancements.",
        };
      case "/social-functions/":
        return {
          backgroundImage: "/images/waterdrop.webp",
          heading: "Social Functions",
          description: "Engage with peers and professionals during our exciting social functions, fostering connections through networking events, dinners, and receptions.",
        };
      case "/call-for-abstracts/":
        return {
          backgroundImage: "/images/waterdrop.webp",
          heading: "Call for Abstracts",
          description: "Submit your abstract to showcase innovative research and contribute to thought-provoking discussions at this prestigious industry event.",
        };
      case "/program-overview/":
        return {
          backgroundImage: "/images/waterdrop.webp",
          heading: "Program Overview",
          description: "Discover a diverse program featuring expert presentations, panel discussions, and workshops, offering insights into the latest industry developments.",
        };
      case "/speakers/":
      return {
        backgroundImage: "/images/waterdrop.webp",
        heading: "Speakers",
        description: "Meet our speakers who are global experts and contribute to the enriching content in the coming 2025 World Groundwater Congress.",
      };
      case "/support-grants/":
        return {
          backgroundImage: "/images/waterdrop.webp",
          heading: "Support Grants",
          description: "The International Association of Hydrogeologists (IAH) Support Grants offers funding and support to groundwater professionals for whom attendance might otherwise not be possible.",
        };
      case "/merchandise/":
        return {
          backgroundImage: "/images/waterdrop.webp",
          heading: "Merchandise",
          description: "Pre-order our custom designed t-shirts and polo shirts and support the IAH community.",
        };
      case "/field-trips/":
        return {
          backgroundImage: "/images/waterdrop.webp",
          heading: "Field Trips",
          description: "Field trips will be held on the lands of the Taungurung, Djaara (Dja Dja Wurrung), Bunurong, Brayakaulung, Eastern Maar, Wadawurrung and Wurundjeri Woi Wurrung Peoples and we wish to acknowledge them as Traditional Owners of these lands.",
        };
      default:
        return {
          backgroundImage: "/images/default.webp",
          heading: "Page Not Found",
          description: "The page you're looking for doesn't exist.",
        };
    }
  };
  